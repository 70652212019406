<template>
  <b-card
    v-if="specialDay"
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->
    <div class="py-3 special-day-title">
      <h1 class="text-white">
        {{ greeting }}
      </h1>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
  },
  data() {
    return {
      CURRENT_DAY: this.$moment().date(),
      CURRENT_MONTH: this.$moment().month() + 1,
      SPECIAL_DAYS: [
        // Aniversaris
        { month: 1, day: 1, greeting: `Feliç any nou! 🥂` }, // Cap d'any
        { month: 1, day: 5, greeting: "Heu fet bonda enguany? 🤴🏿🤴🤴🏻" }, // Reis d'Orient
        { month: 1, day: 6, greeting: "Han arribat carregats? 🎁" }, // Reis d'Orient
        { month: 1, day: 10, greeting: "I demà... Sa Llego! 🏃‍♀️🏃‍♂️🏃" }, // Festes de Sant Antoni [ACTUALITZAT PER 2025]
        { month: 1, day: 11, greeting: "Sa Llego 🏃‍♀️🏃‍♂️🏃" }, // Festes de Sant Antoni [ACTUALITZAT PER 2025]
        { month: 1, day: 15, greeting: "Espinagades a punt? 🔥👹" }, // Festes de Sant Antoni
        { month: 1, day: 16, greeting: "Bones festes! 🔥👹" }, // Festes de Sant Antoni
        { month: 1, day: 17, greeting: "Visca Sant Antoni! 🔥👹" }, // Festes de Sant Antoni
        { month: 1, day: 19, greeting: "Sa darrera estirada! 🔥👹" }, // Festes de Sant Antoni
        { month: 1, day: 20, greeting: "I avui... Sant Sebastià! 🔥👹" }, // Festes de Sant Antoni
        { month: 3, day: 1, greeting: "Visca sa nostra terra! 🙌" }, // Dia de les Illes Balears
        { month: 3, day: 10, greeting: "Molts d'anys Pedro! 🥳" },
        {
          month: 3,
          day: 19,
          greeting: "Molts d'anys a tots els pares (i als Joseps)! 🫶",
        }, // Sant Josep i dia del Pare
        { month: 3, day: 29, greeting: "Avui vespre: 🕑 ➡️ 🕒" }, // Canvi a horari d'estiu [ACTUALITZAT PER 2025]
        { month: 4, day: 17, greeting: "Panades time! 😋" }, // Festes de Pasqua [ACTUALITZAT PER 2025]
        { month: 4, day: 18, greeting: "Panades time! 😋" }, // Festes de Pasqua [ACTUALITZAT PER 2025]
        { month: 4, day: 19, greeting: "Panades time! 😋" }, // Festes de Pasqua [ACTUALITZAT PER 2025]
        { month: 4, day: 20, greeting: "Panades time! 😋" }, // Festes de Pasqua [ACTUALITZAT PER 2025]
        { month: 4, day: 22, greeting: "Tothom cap a Crestatx! 🕺" }, // Crestatx
        { month: 4, day: 23, greeting: "I avui... Sant Jordi! 🌹📚" }, // Sant Jordi
        { month: 5, day: 1, greeting: "Tothom a descansar! 💆‍♀️" }, // Dia del treballador
        { month: 5, day: 4, greeting: "Molts d'anys a totes ses mares! 🫶" }, // Dia de les mares [ACTUALITZAT PER 2025]
        { month: 6, day: 24, greeting: "Molts d'anys Joans i Joanes! 🐴" }, // Sant Joan
        { month: 6, day: 29, greeting: "I avui... Sant Pere i Sant Pau! 🥳" }, // Sant Pere i Sant Pau
        { month: 7, day: 6, greeting: "Molts d'anys Toni! 🥳" },
        { month: 7, day: 20, greeting: "Santa Margalida, visca sa Pobla! 🥳" }, // Santa Margalida
        { month: 7, day: 25, greeting: "Sant Jaume, visca sa Pobla! 🥳" }, // Sant Jaume
        { month: 7, day: 28, greeting: "Molts d'anys Francisca! 🥳" },
        { month: 8, day: 1, greeting: "Molts d'anys Biel! 🥳" },
        { month: 8, day: 2, greeting: "La Patrona! 👳🏾‍♀️" }, // La Patrona
        { month: 8, day: 15, greeting: "I avui... Patos! 🐥" }, // Mare de Déu d'agost
        { month: 8, day: 21, greeting: "Molts d'anys Pep! 🥳" },
        { month: 9, day: 11, greeting: "Aniversari Magdalena Son Janer 🥳" },
        { month: 10, day: 4, greeting: "I avui... Sant Francesc d'Assís! 🥳" }, // Sant Francesc
        { month: 10, day: 31, greeting: "Halloween! 🎃" }, // Halloween
        { month: 10, day: 25, greeting: "Avui vespre: 🕒 ➡️ 🕑" }, // Canvi a horari d'hivern [ACTUALITZAT PER 2025]
        { month: 11, day: 1, greeting: "Tots Sants! 💐🪦" }, // Tots Sants
        { month: 11, day: 27, greeting: "Fira de tardor 2️⃣8️⃣ ➡️ 3️⃣0️⃣" }, // Fira de Tardor a Sa Pobla [ACTUALITZAT PER 2025]
        { month: 11, day: 28, greeting: "Fira de tardor 2️⃣8️⃣ ➡️ 3️⃣0️⃣" }, // Fira de Tardor a Sa Pobla [ACTUALITZAT PER 2025]
        { month: 11, day: 29, greeting: "Fira de tardor 2️⃣8️⃣ ➡️ 3️⃣0️⃣" }, // Fira de Tardor a Sa Pobla [ACTUALITZAT PER 2025]
        { month: 11, day: 30, greeting: "Fira de tardor 2️⃣8️⃣ ➡️ 3️⃣0️⃣" }, // Fira de Tardor a Sa Pobla [ACTUALITZAT PER 2025]
        { month: 12, day: 5, greeting: "Pont de la constitució 6️⃣ ➡️ 8️⃣" }, // Pont de la constitució
        { month: 12, day: 6, greeting: "Pont de la constitució 6️⃣ ➡️ 8️⃣" }, // Pont de la constitució
        { month: 12, day: 7, greeting: "Pont de la constitució 6️⃣ ➡️ 8️⃣" }, // Pont de la constitució
        { month: 12, day: 8, greeting: "Pont de la constitució 6️⃣ ➡️ 8️⃣" }, // Pont de la constitució
        { month: 12, day: 23, greeting: "Bon Nadal! 🎅🏿" }, // Festes de Nadal
        { month: 12, day: 24, greeting: "Bon Nadal! 🎅🏿" }, // Festes de Nadal
        { month: 12, day: 25, greeting: "Bon Nadal! 🎅🏿" }, // Festes de Nadal
        { month: 12, day: 26, greeting: "I avui... Sant Esteve! 🥳" }, // Festes de Nadal
        { month: 12, day: 31, greeting: `Nit de cap d'any! 🎆` }, // Cap d'any
      ],
    };
  },
  computed: {
    specialDay() {
      if (!this.SPECIAL_DAYS.length) return null;

      for (let i = 0; i < this.SPECIAL_DAYS.length; i++) {
        const specialDay = this.SPECIAL_DAYS[i];
        if (
          specialDay.day === this.CURRENT_DAY &&
          specialDay.month === this.CURRENT_MONTH
        ) {
          return specialDay;
        }
      }

      return null;
    },
    greeting() {
      if (!this.specialDay) return null;
      return this.specialDay.greeting || "Bones festes! 🥳";
    },
  },
};
</script>

<style lang="scss">
.special-day-title {
  position: relative;
}
.special-day-title > h1 {
  font-size: 2.5rem;
  z-index: 100;
}
</style>
